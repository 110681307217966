import { Routes, Route } from 'react-router-dom';

import { Footer } from './Footer/Footer';
import { Main } from './Main/Main';
import { Terms } from './Terms/Terms';
import { PersonalInformation } from './PersonalInformation/PersonalInformation';
import { NotFound } from '../404';
import { CookieRules } from './CookieRules/CookieRules';

export const WisdomFromTheAshes = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Main />}/>
        <Route path="terms" element={<Terms />} />
        <Route path="personal-info-rules" element={<PersonalInformation />} />
        <Route path="cookies-policy" element={<CookieRules />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </>
  )
}
