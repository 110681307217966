import { useState } from 'react';

import { Spinner } from 'react-bootstrap';
import { OrderForm } from '../OrderFormAntistres/OrderForm';
import emailjs from '@emailjs/browser';

import styles from './Main.module.css';
import { About } from '../About/About';
import { Landing } from '../Landing/Landing';
import { PageExamples } from '../PageExamples/PageExamples';


export const Main = () => {
    const [hasOrdered, setHasOrdered] = useState(false);
    const [isSuccessful, setIsSuccessful] = useState(true);
    const [isLoading, setIsLoading] = useState(false);


    const onOrderSubmit = async (data) => {
        setHasOrdered(true);
        delete data.agreed;
        const order = {...data};
        order.firstName = data.firstName[0].toUpperCase() + data.firstName.slice(1);
        order.lastName = data.lastName[0].toUpperCase() + data.lastName.slice(1);
        
        const serviceId = process.env.REACT_APP_EMAIL_ANTISTRESS_SERVICE_ID;
        const orderTemplate = process.env.REACT_APP_EMAIL_ANTISTRESS_ORDER_TEMPLATE;
        const publicKey = process.env.REACT_APP_ANTISTRESS_PUBLIC_KEY;
        const responseTemplate = process.env.REACT_APP_EMAIL_ANTISTRESS_RESPONSE_TEMPLATE;

        setIsLoading(true);

        emailjs.send(serviceId, orderTemplate, order, {
            publicKey: publicKey
        }).then(
            (response) => {
              setTimeout(() => {
                    setIsLoading(false);
                    setIsSuccessful(true);
                }, 1000);
              console.log('SUCCESS!', response.status, response.text);
              emailjs.send(serviceId, responseTemplate, order, {
                publicKey: publicKey
                }).then((response) => {
                    console.log('Confirmation Sent!', response.status);
                }, (error) => {
                    console.log('OOPS', error);
                });
            },
            (error) => {
              setIsLoading(false);
                setIsSuccessful(false);
                setTimeout(() => {
                setHasOrdered(false);
                }, 1000);
              console.log('FAILED...', error);
            },
         );

        // try{
        // setIsLoading(true);
        // const result = await createOrder(order);
        // setTimeout(() => {
        //     setIsSuccessful(true);
        //     setIsLoading(false);
        // }, 1000)
        // console.log(result);
        // } catch(err){
        // setIsLoading(false);
        // setIsSuccessful(false);
        // setTimeout(() => {
        //     setHasOrdered(false);
        // }, 1000);
        // }
    }
    return(
        <>
            <About/>
            <Landing/>
            <PageExamples/>
            {
                !hasOrdered && <OrderForm onSubmitHandler={onOrderSubmit}/>
                }
                {
                hasOrdered && 
                <div className={styles.alertDiv}>
                    {
                        (!isLoading && isSuccessful) && 
                        <>
                            <img className={styles.responseImg} src="/Success-Antistress-Therapy.png" alt="success" />
                        </>
                    }
                    {
                        (!isLoading && !isSuccessful) && 
                        <>
                            <img className={styles.responseImg} src="/Error.png" alt="error" />
                        </>
                    }
                    {
                        isLoading && 
                        <div className={styles.spinnerContainer}>
                            <div className={styles.spinnerInnerContainer}>
                                <Spinner className={styles.spinner} animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            </div>
                        </div>
                    }
                </div>
                }
        </>
    )
}