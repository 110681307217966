import styles from './Landing.module.css';
import { HashLink } from 'react-router-hash-link';

export const Landing = () => {
  return (
    <div className={styles.container}>
        <div className={styles.main}>
            <div className={styles.imgContainer}>
                <img className={styles.img} src="/images/Antismoking-cards/Cards-box-landing.png" alt="aboutImg" loading="lazy" />
            </div>
            <div className={styles.descContainer}>
                <h1 className={styles.title}>МОТИВАЦИОННИ КАРТИ</h1>
                <p className={styles.desc}>
                    Цигарите са едно от нещата, които не трябва да търпим и за секунда, защото те ни съсипват здравето, скъсяват живота и един ден ще са причина да изпитаме много болка и мъка.
                    <br />
                    Когато този ден настъпи е логично да се запитаме как сме могли да бъдем толкова глупави да си причиним такова нещо. За да не се стига до там трябва да предприемем мерки сега.
                    <br />
                    Проблема е, че когато пушенето ти стане навик е много трудно да ги откажеш, защото и те, като всеки друг наркотик, са силно пристрастяващи. Изискват се много сили, воля и силна мотивация, за да се справиш с тях. Тези карти ще ти помогнат да увеличиш решимостта си и да останеш непреклонен през трудния път за отказване от тази отрова.
                </p>
            </div>
        </div>
        <div className={styles.btnContainer}>
            <HashLink smooth to="/motivacionni-karti/#order-form" className={styles.order}>Поръчай тук</HashLink>
        </div>
    </div>
  )
}
