import styles from './About.module.css';
import { HashLink } from 'react-router-hash-link';

export const About = () => {
    return(
        <div className={styles.container}>
            <h2 className={styles.title}>За книгата</h2>
            <div className={styles.about}>
                <div className={styles.aboutDescContainer}>
                    <p className={styles.aboutDesc}>
                        "Мъдрости от пепелта" е моят личен прочит на живота, изразен чрез нестандартни, кратки сентенции и мисли. Това е своеобразно пътешествие през предизвикателствата, които съм минал. Всяка страница е пропита с уроците и поуките, които съм научил по възможно най-трудния начин. Открийте вдъхновяващи истини, които могат да бъдат родени, единствено от силни емоции и преживявания.
                        <br />
                        <br />
                        Като непознат автор, разбирам вашите съмнения за стойността на творбата, затова ви предоставям възможност да прецените сами нейното качество.
                        <br />
                        <br />
                        Хубавото в случая е, че това е сборник със сентенции, които са кратки и всяка е сама за себе си. Така като прочетете няколко страници има голям шанс да придобиете представа какво ви очаква като цяло. Малка част от книгата е на Ваше разположение по-надолу в сайта.
                    </p>
                </div>
                <div className={styles.aboutImgContainer}>
                    <img className={styles.aboutImg} src="/images/Wisdom-From-The-Ashes/Heart.webp" alt="aboutImg" loading="lazy" />
                </div>
            </div>
            <div className={styles.btnContainer}>
                <HashLink smooth to="/madrosti-ot-pepelta/#order-form" className={styles.order}>Поръчай тук</HashLink>
            </div>
        </div>
    )
}