const pageView = () => {
   window.fbq('trackSingle', '566317702591030', 'PageView');
}
const purchase = () => {
    window.fbq('trackSingle', '566317702591030', 'Purchase', {
        value: 7,
        currency: 'EUR'
    });
}
export default {
    pageView,
    purchase
}