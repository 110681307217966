import styles from './PageExamples.module.css';

import { useState } from 'react';
import { pages } from './examples';


export const PageExamples = () => { 
  const [activePage, setActivePage] = useState(0);
  const [loading, setLoading] = useState(false);

  function changeActivePage(next){
    setLoading(true);
    next ? setActivePage(activePage + 1) : setActivePage(activePage - 1);
    let pageElement = document.querySelector(`.${styles.mainContainer}`);

    if (pageElement) {
      pageElement.scrollIntoView({behavior: 'smooth'});
    }
    
    setTimeout(()=>{
      setLoading(false);
    }, 500);
  }

  return (
    <div className={styles.main}>
        <h2 className={styles.title}>Примерни страници <br/> от дневника</h2>
        <div className={styles.subContainer}>
          <button disabled={activePage === 0 || loading} onClick={() => changeActivePage(false)} className={styles.upperBtn}><i className="fa-solid fa-angles-left"></i></button>
          <div className={styles.mainContainer}>
              <div className={styles.elementsContainer}>
                  { loading 
                    ? <div className={styles.loaderContainer}><i className={`${styles.loader} fa fa-gear fa-spin`}></i></div>
                    : <div className={styles.imageContainer}><img className={styles.image} src={pages[activePage][0]} alt="img"/></div>}
              </div>
              <div className={styles.elementsContainer}>
                  { loading 
                    ? <div className={styles.loaderContainer}><i className={`${styles.loader} fa fa-gear fa-spin`}></i></div>
                    : <div className={styles.imageContainer}><img className={styles.image} src={pages[activePage][1]} alt="img"/></div>}
              </div>
          </div>
          <button disabled={activePage === 2 || loading} onClick={() => changeActivePage(true)} className={styles.upperBtn}><i className="fa-solid fa-angles-right"></i></button>
        </div>
        <div className={styles.bottomBtnContainer}>
          <button disabled={activePage === 0 || loading} onClick={() => changeActivePage(false)} className={styles.bottomBtn}><i className="fa-solid fa-angles-left"></i></button>
          <button disabled={activePage === 2 || loading} onClick={() => changeActivePage(true)} className={styles.bottomBtn}><i className="fa-solid fa-angles-right"></i></button>
        </div>
    </div>
  )
}
