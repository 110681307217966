import styles from './Landing.module.css';


export const Landing = () => {
  return (
    <div className={styles.bigContainer}>
        <div className={styles.smallerContainer}>
            <div>
                <h2 className={styles.sideTitle}>Lorem, ipsum dolor</h2>
                <p className={styles.sideText}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil totam cumque quod perspiciatis a quae, obcaecati, voluptates impedit dolore dolorem laudantium, magnam repellendus consequatur minima animi iusto reprehenderit laborum molestias!</p>
            </div>
            <div className={styles.imageContainer}>
                <img className={styles.image} src="/images/Antistress-therapy/About-Image.png" alt="main png" />
            </div>
            <div>
                <h2 className={styles.sideTitle}>Lorem, ipsum dolor</h2>
                <p className={styles.sideText}>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Rem reprehenderit magni optio neque sequi. Dolorum natus vel provident blanditiis, voluptate voluptates possimus cum, repudiandae nobis, aliquid quo iusto. Eligendi, laboriosam!</p>
            </div>
        </div>
    </div>
  )
}
