import { Routes, Route } from 'react-router-dom';
import { NotFound } from '../404';
import { Footer } from './Footer/Footer';
import { Main } from './Main/Main';
import { PersonalInformation } from './PersonalInformation/PersonalInformation';
import { Terms } from './Terms/Terms';
import { CookieRules } from './CookieRules/CookieRules';


export const AntiSmokingCards = () => {
  return (
    <>
        <Routes>
            <Route path="/" element={<Main/>}/>
            <Route path="personal-info-rules" element={<PersonalInformation/>}/>
            <Route path="terms" element={<Terms/>}/>
            <Route path="cookies-policy" element={<CookieRules/>}/>
            <Route path="*" element={<NotFound/>}/>
        </Routes>
        <Footer/>
    </>
  )
}
