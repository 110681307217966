const pageView = () => {
    window.fbq('trackSingle', '915175413993894', 'PageView');
 }
 const purchase = () => {
     window.fbq('trackSingle', '915175413993894', 'Purchase', {
         value: 8.5,
         currency: 'EUR'
     });
 }
 export const EventsToTrack = {
     pageView,
     purchase
 }