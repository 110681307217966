import styles from "./Terms.module.css";

export const Terms = () => {
  return (
    <div className={styles.terms}>
        <div className={styles.container}>
            <h1 className={styles.title}>ОБЩИ УСЛОВИЯ ЗА ПОЛЗВАНЕ НА САЙТА</h1>
            <h2 className={styles.subtitle}>Основни принципи</h2>
            <p className={styles.rule}>
                Скъпи читатели, благодаря ви, че избрахте да посетите нашият уебсайт.
                Моля да се запознайте с настоящите обобщени основни принципи за ползване
                на електронния магазин на k-kostadinov.store, както и с пълните Общи
                условия приложени по-долу. Настоящите Общи условия представляват правно
                споразумение със задължителна, обвързваща сила между електронен магазин
                k-kostadinov.store и потребителите (Вие) във връзка с услугите, предлагани
                от k-kostadinov.store. Вие се съгласявате, приемате и се задължавате да
                спазвате тези Условия за обслужване. Собственик на електронния магазин
                k-kostadinov.store е Красимир Костадинов. Електронна поща за връзки
                misli_ot_pepelta@gmail.com
            </p>
            <h2 className={styles.subtitle}>Поръчки</h2>
            <p className={styles.rule}>
                Всяка поръчка е свързана със задължение за плащане с наложен платеж.
                Приемаме поръчки 24 часа в денонощието, в почивните дни и в дните на
                официалните празници. Поръчката на продукт(и) през онлайн магазина
                означава изразено съгласие за сключване на договор за дистанционна
                продажба, който се регулира от Закона за защита на потребителите.
                Извършването на поръчки няма нужда от регистрация като потребител, а
                само от предоставянето на коректни данни за поръчителя. Поръчката на
                стоки се извършва чрез спазване на следните основни технически стъпки:
                Избор на опцията „Направете Поръчка” Посочване на Вашите данни /име,
                фамилия, електронна поща, телефон/, посочване на адрес за доставка;
                Избор на куриер и начин на доставка: - до адрес - до офис на куриер
                Метод за плащане - наложен платеж Маркиране на допълнителните отметки за
                запознаване с общите условия и за съгласие за обработване на лични
                данни; Натискане на бутона “Завърши поръчката”, което представлява
                финално действие по поръчката. В случай, че клиентът е посочил непълен,
                неверен или грешен адрес, email и/или телефонен номер при подаване на
                поръчката, възпрепятстващ изпълнението на поръчката, се счита, че същата
                е невалидна.Само потвърждаването на поръчката от страна на Продавача
                гарантира доставката на продукта. Потвърждението на поръчката се изпраща
                на електронния адрес посочен от Клиента при подаването на поръчката.
                Цени “Продажна цена” е крайната цена за бройка. В продажната цена не са
                включени разходите за доставка. Проблеми при изпълнение на поръчката В
                случай, че заявените стоки не са налични, ние ще Ви уведомим по телефон
                или чрез имейл.
            </p>
            <h2 className={styles.subtitle}>Плащане</h2>
            <p className={styles.rule}>
                Следва да заплатите закупената книга, след нейното получаване от куриер.
                Книгата се доставя само в пределите на Република България. Посочените
                цени са в български левове с включени всички данъци и такси за единична
                бройка и не включват пощенски или транспортни разходи за доставка на
                стоката, като последните се начисляват отделно. Запазваме правото си да
                променяме едностранно посочените на сайта цени, като промяната на цените
                има сила само за договори, сключени след нейното обявяване на сайта.
                Цената следва да се заплати, при условие, че сме изпълнили от вас
                одобрената поръчка: – чрез наложен платеж при доставка на стоката на
                представител на съответната куриерска фирма;
            </p>
            <h2 className={styles.subtitle}>
                Съдържание и авторски права и защита на интелектуалната собственост
            </h2>
            <p className={styles.rule}>
                Всички елементи на съдържанието на сайта на k-kostadinov.store, включително
                дизайн, софтуерни програми, текстове, бази данни, графики, рисунки,
                скици и др., са обект на закрила по Закона за авторското право и
                сродните му права и не могат да бъдат използвани в нарушение на
                действащото законодателство. Правото на достъп на Клиента не включва
                правото да се копира, възпроизвежда или променя информация,
                представляваща обект на интелектуална собственост.
            </p>
        </div>
    </div>
  );
};