import styles from './CardsExample.module.css';

export const CardsExample = () => {
  return (
    <div className={styles.bigContainer}>
        <h2 className={styles.title}>Примерни карти от тестето</h2>
        <div className={styles.cardsContainer}>
            <div className={styles.imgContainer}>
                <img className={styles.img} src="https://i.imgur.com/RDdLJKz.png" alt="Card" />
            </div>
            <div className={styles.imgContainer}>
                <img className={styles.img} src="https://i.imgur.com/t3sfrkZ.png" alt="Card" />
            </div>
            <div className={styles.imgContainer}>
                <img className={styles.img} src="https://i.imgur.com/lCTOCuf.png" alt="Card" />
            </div>
            <div className={styles.imgContainer}>
                <img className={styles.img} src="https://i.imgur.com/uSZmChs.png" alt="Card" />
            </div>
            <div className={styles.imgContainer}>
                <img className={styles.img} src="https://i.imgur.com/76WQWpk.png" alt="Card" />
            </div>
            <div className={styles.imgContainer}>
                <img className={styles.img} src="https://i.imgur.com/Ui5DxXN.png" alt="Card" />
            </div>
        </div>
    </div>
  )
}
