import styles from './About.module.css';
import { HashLink } from 'react-router-hash-link';

export const About = () => {
  return (
    <div className={styles.container}>
        <div className={styles.about}>
            <div className={styles.aboutImgContainer}>
                <img className={styles.aboutImg} src="/images/Antistress-therapy/no-shadow.png" alt="aboutImg" loading="lazy" />
            </div>
            <div className={styles.aboutDescContainer}>
                <h2 className={styles.title}>АНТИСТРЕС КУТИЯ</h2>
                <p className={styles.aboutDesc}>
                    "Мъдрости от пепелта" е моят личен прочит на живота, изразен чрез нестандартни, кратки сентенции и мисли.
                    <br />
                    <br />
                    Като непознат автор, разбирам вашите съмнения за стойността на творбата, затова ви предоставям възможност да прецените сами нейното качество.
                    <br />
                    <br />
                    Хубавото в случая е, че това е сборник със сентенции, които са кратки и всяка е сама за себе си.
                </p>
            </div>
        </div>
        <div className={styles.btnContainer}>
            <HashLink smooth to="/antistres-kutiya/#order-form" className={styles.order}>Поръчай тук</HashLink>
        </div>
    </div>
  )
}
