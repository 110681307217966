import 'bootstrap/dist/css/bootstrap.min.css';

import './App.css';

import { Routes, Route } from 'react-router-dom';

import { Home } from './components/Home';
import { WisdomFromTheAshes } from './components/WisdomFromTheAshes/WisdomFromTheAshes';
import { AntistressTherapy } from './components/AntistressTherapy/AntistressTherapy';
import { NotFound } from './components/404';
import { AntiSmokingCards } from './components/AntiSmokingCards/AntiSmokingCards';

function App() {
  return (
    <>
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/madrosti-ot-pepelta/*' element={<WisdomFromTheAshes/>}/>
        <Route path='/antistres-kutiya/*' element={<AntistressTherapy/>}/>
        <Route path='/motivacionni-karti/*' element={<AntiSmokingCards/>}/>
        <Route path='*' element={<NotFound/>}/>
      </Routes>
    </>
  );
}

export default App;
