import { useEffect, useState } from 'react';

import { Landing } from '../Landing/Landing';
import { About } from '../About/About';
import { ContentDesc } from '../ContentsDesc/ContentDesc';
import { Bonus } from '../Bonus/Bonus';
import { OrderForm } from '../OrderForm/OrderForm';
//import { createOrder } from '../../services/orderService';
import emailjs from '@emailjs/browser';
import { Spinner } from 'react-bootstrap';
import EventsToTrack from '../EventsToTrack/EventsToTrack';

import styles from './Main.module.css';


export const Main = () => {
    useEffect(() => {
        if (window.fbq) {
            console.log('just registered page view')
            EventsToTrack.pageView();
        } else{
            console.log("pixel not loaded yet");
        }
    }, []);

    const [hasOrdered, setHasOrdered] = useState(false);
    const [isSuccessful, setIsSuccessful] = useState(true);
    const [isLoading, setIsLoading] = useState(false);


    const onOrderSubmit = async (data) => {
        setHasOrdered(true);
        delete data.agreed;
        const order = {...data};
        order.firstName = data.firstName[0].toUpperCase() + data.firstName.slice(1);
        order.lastName = data.lastName[0].toUpperCase() + data.lastName.slice(1);
        
        const serviceId = process.env.REACT_APP_EMAIL_SERVICE_ID;
        const orderTemplate = process.env.REACT_APP_EMAIL_ORDER_TEMPLATE;
        const publicKey = process.env.REACT_APP_PUBLIC_KEY;
        const responseTemplate = process.env.REACT_APP_EMAIL_RESPONSE_TEMPLATE;

        setIsLoading(true);

        EventsToTrack.purchase();

        emailjs.send(serviceId, orderTemplate, order, {
            publicKey: publicKey
        }).then(
            (response) => {
              setTimeout(() => {
                    setIsLoading(false);
                    setIsSuccessful(true);
                }, 1000);
              console.log('SUCCESS!', response.status, response.text);
              emailjs.send(serviceId, responseTemplate, order, {
                publicKey: publicKey
                }).then((response) => {
                    console.log('Confirmation Sent!', response.status);
                }, (error) => {
                    console.log('OOPS', error);
                });
            },
            (error) => {
              setIsLoading(false);
                setIsSuccessful(false);
                setTimeout(() => {
                setHasOrdered(false);
                }, 1000);
              console.log('FAILED...', error);
            },
         );

        // try{
        // setIsLoading(true);
        // const result = await createOrder(order);
        // setTimeout(() => {
        //     setIsSuccessful(true);
        //     setIsLoading(false);
        // }, 1000)
        // console.log(result);
        // } catch(err){
        // setIsLoading(false);
        // setIsSuccessful(false);
        // setTimeout(() => {
        //     setHasOrdered(false);
        // }, 1000);
        // }
    }

    return(
        <>
            <Landing />
            <About />
            <ContentDesc />
            <Bonus />
            {
            !hasOrdered && <OrderForm onSubmitHandler={onOrderSubmit}/>
            }
            {
            hasOrdered && 
            <div className={styles.alertDiv}>
                {
                    (!isLoading && isSuccessful) && 
                    <>
                        <img className={styles.responseImg} src="/images/Wisdom-From-The-Ashes/Success.png" alt="success" />
                    </>
                }
                {
                    (!isLoading && !isSuccessful) && 
                    <>
                        <img className={styles.responseImg} src="/Error.png" alt="error" />
                    </>
                }
                {
                    isLoading && 
                    <Spinner className={styles.spinner} animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                }
            </div>
            }
        </>
    )
}