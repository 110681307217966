import styles from './About.module.css';

export const About = () => {
  return (
    <div className={styles.bigContainer}>
        <div className={styles.mainContainer}>
            <div className={styles.imageContainer}>
                <img className={styles.image} src="/images/Cards-Sticker.png" alt="main png" loading="lazy"/>
            </div>
            <div className={styles.txtsContainer}>
                <div className={styles.txtContainer}>
                    <h2 className={styles.subTitle}>Съдържание</h2>
                    <p className={styles.desc}>Това са сто различни кратки сентенции, 
                        събрани в петдесет черно-бели карти,
                        чиято цел е да те вдъхновят за промяна
                        и помогнат през трудния път на отказване
                        от този изключително вреден навик.</p>
                </div>
                <div className={styles.txtContainer}>
                    <h2 className={styles.subTitle}>Как да ги използвам</h2>
                    <p className={styles.desc}>Всеки път, когато ти се допуши, вместо
                        цигарата хвани картите и чети от тях,
                        докато отмине порива. Прави това всеки 
                        път, когато изпиташ желание да запалиш.</p>
                </div>
                <div className={styles.txtContainer}>
                    <h2 className={styles.subTitle}>Поздравявам</h2>
                    <p className={styles.desc}>Всички за желанието да променят живота си и да станат по-здрави! Пожелавам Ви успех и искрено се надявам тези карти да Ви помогнат в борбата за спасяването на Вашия живот!</p>
                </div>
            </div>
        </div>
    </div>
  )
}
